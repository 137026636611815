/** @jsx jsx */
import { jsx, css, withTheme } from "@emotion/react";

const Border = ({ fillColor, ...props }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 1440 56.7"
    // style="enable-background:new 0 0 1440 56.7;"
    xmlSpace="preserve"
    css={css`
      display: block;
      position: absolute;
      width: 100%;
      z-index: 1;
    `}
    {...props}
  >
    <g transform="translate(0.000000, -1589.000000)">
      <path
        d="M1440,1645.7c0,0,7.4-38.6-14.2-29.1c-0.2,3.2-4.8,2.9-7.6,2.3c-5.2-0.9-10.3-2.6-15.5-3.5
			c-12.8-0.1-17.6,6.9-30.7-1.7c-7.8-6.1-12.3,8.2-22.3-0.4c-11.5-11.8-26.8,0-41.5-4c-1.8,0-2.9,0.1-4.4,0.2
			c0.6-0.8,1.9-1.7,2.3-2.7c-1,0.1-2.5,0.3-3.3,0.4c-6.5,5.6-14,4.9-22.9,4.3c-26.7-1-59.5-4.8-84.6,0.1c0.1,0.6,1.9,1.8,1.7,2.3
			c-1.2-1.1-4.8-4.8-5.9-5.1c6.2,5.5-8.5,2.3-10.6,3.5c-3.2-3.8-9.4-7.3-14.2-9.9c-7.6-4.3-9.7,9.6-16.8,4.7c-2.3-1.1-1.4,0.8-0.6,2
			c-13.5-3.8-33.3-10.2-44.8,0.2c-11.1,5.3-39.6-2.3-41.5-3c-8.2,0.8-10.2,2.7-5.9-5.6c-3.7,3.5-10.4,0.5-15.1-0.1
			c-9.8-0.2,7.6,6.7-15.4,8c2-7.1,2.3-2.7-4.3-7c-9.4,3.1-18.9,2.7-28.6,1.7c-6-0.4-11.5-0.2-16,2.8c-5.3-8.4-16-0.9-25.1-4.6
			c-5-0.9-10.3-2.1-15.2-2.8c-7.3-1-14.7-0.7-20.7,3.8c-12.2-0.9-44.4-8.2-51.8-5.7c-0.2,0.2-0.6,0.6-0.8,0.8c0.9,0.3-0.9,0.3-0.9,1
			c-0.4,0.5,0.4-0.5,0.4-0.6c-9.5-2-21.7-1.3-32-1.6c-16.4-1.7-30.2,11-45.1,5.1c-10.6-1.6-25.3-10.9-29.3-0.5
			c-0.8,0.1-1.8,0.1-2.6,0.1c-10.3-0.9-9.4,0.6-16.5-3.3c-3.7,0.2-8.4,1.6-10.4-2.7c6.4,10.2-23.6,3.8-26.4,5.8
			c1.6,0.5,4.2,4,0.6,3.2c-1.9-0.4-4.2-1.5-6.2-0.8c-0.2,0.5-6.5-6.5-6.7-6.4c7.1,14-5.1,1.6-1.5,7.6c-7.3,5.6-14.3-8.2-16.4-4.9
			c0.1,4.3-6.3,0.6-4-1.6c3.4-4.4-6.6,5-13,1.4c0,0-0.5-0.1-0.5-0.1c8.8,3.9-1.4,1.9-6,3.8c5.2,21.1-15.5-1.1-25.8-2.9
			c-2.6,6.7-4.7,9-13.1,10c-0.7,4.4-6.4,3.2-11.1,2.9c-2-0.2-4.2-0.6-6.2-1c6.1-1.8-10.2-2-10.1-2.6c1.3,6.5-5.6,1.9-8.6,1.9
			c0.2,8.3-15.7,3.2-20.6,0.7c-1.7-0.2,1.8-1.7,1.8-2c-1.8-0.8-3-5.8-3.8-8c-0.7-2.3-1.6-5.6-2.4-7.9c-5.9-0.2-4.2-6.5-11.4-3.1
			c7,8.4-3.1,6.7-7.1,1.9c-7.1-8.2,6.2,6.1-9.8,11.7c-13.4-0.1-27.1-14.3-35,3.3c-8.3-0.2-3.7-10.2-14.2-5.8
			c-1.6,0.2-3.1-0.1-3.2-1.2c-1.5-5.9-10.3,1.7-14.2-0.7c-12.3-0.2-15-9.6-18.8-6c-1.6,1-3.4,2.9-5.2,3.1c-0.9-11-28.3,5.3-38.7,6.1
			c-5.3,2.4-8.9,6.7-14.8,7c-2.6-0.8-8.8,0.1-3.8-3.7c-7.4,1.5-15.5,3.1-23,4c-8.8,0.1-30.8,3.4-35.5-3.8c2.4,11-13.5,8-20.1,6.8
			c-27.9,0,30.2,4.9,35.1,4.2c2.5-1.2,1,4,1.4,4.9c-4,1.3-12.4,6.4-14.3-2.7c-8.8,3.7-13.6,3.6-20.9,2.8c-6.5-1-19.2,0.6-21.4-5.6
			c-0.9-0.1-1.7-0.4-0.5-1.2c-0.5,0.6-3.9,1.8-4.5,2.4c-4.8,2.6-9.8,4.6-15,6.2c0,0,8.9,0,8.9,0c-1.4,0.9-11.6,9.7-13,4.7
			c-4.2-9.4-16.5-0.7-20.7,4.9c-4.9-5.3-15.8,2-21.7,3.7c-17,8.8,19.2-20.6-0.5-13.6c-7.3,2.1-20.7,0.4-24.7,1.3
			c1.8,4.8-6.1,1.9-7.5,0.5c-3.9,5.3-7.8-0.8-7.2-5.4c2.6-20.1-32.6,4-41.3-1.5c0.7-0.5,1.3-1.1,1.7-1.8c0.6-1.2-1.7-0.1-2.2,0.3
			c-1.6,0.9,0.2-1.1,1.2-1.6c7.8-4.8-5,1.1-8.2,2.6c-8.7,4.3-7.3-4.2-17.5-1.1c-3.9,0.7-8.8,2-12.7,2.6c-3.2-0.3-7.3,4.6-0.8,4.4
			c0.4,0.1-17.5,9.5-9.6,1.9c0.9-0.8,1.1-2.2-0.5-1.9c-7,0.3-5.8,9-13.1,8.7c-23.4,3.7,2.9-5.8,7.5-9.3c0,0-4.1-2-4.1-2
			c0.9-1.3,1.7-2.9,3.1-3.7c-1.2-0.1-27.1,0.8-28.5-0.8c-0.8-0.6-2.4,0.2-3,1c-0.4-0.1-2.9,0.3-3.3-0.3c-1.2-0.6-3.1-0.9-4.3-1.7
			c-0.2-6.9-8.8-1.2-12-6.6c-2.8-5.4-4.2-9.8-10.5-3.2c-11.3,8.1-8.2,38.4-8.2,38.4H1440z"
        fill={fillColor}
      />
    </g>
  </svg>
);

const RuggedBorder = withTheme(
  ({ theme, children, borderColor, xBorder, ...props }) => {
    const borders = [
      `
      left: 0px;
      bottom: 0px;
      margin-bottom: -1px;
      `,
      `
      left: 0px;
      top: 0px;
      margin-top: -1px;
      transform: rotate(180deg);
      `,
    ];
    if (xBorder) {
      borders.push(
        `
    left: -50%;
    bottom: 50%;
    margin-left: -1px;
    transform: rotate(90deg);
    `,
        `
    right: -50%;
    bottom: 50%;
    margin-right: -1px;
    transform: rotate(270deg);
    `
      );
    }
    return (
      <div
        css={css`
          position: relative;
          overflow: hidden;
        `}
        {...props}
      >
        {children}
        <div aria-hidden="true">
          {borders.map((b, i) => (
            <Border
              key={i}
              fillColor={borderColor || theme.color.spot1}
              css={css(b)}
            />
          ))}
        </div>
      </div>
    );
  }
);

export default RuggedBorder;
